import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Hearts } from 'react-loader-spinner';

import Event from "../../components/Event";
import Header from "../../components/Header";

import { fetchEvents, } from "../../actions/eventAction";

function Home() {
  let userInputRef = useRef(null)
  const dispatch = useDispatch()
  const [tiers, setTiers] = useState([])
  const eventObj = useSelector(state => state.eventObj)
  const events = eventObj.events

  // Get all tiers
  useEffect(() => {
    fetch('/api/get_tiers')
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setTiers(data)
      });
  }, []);

  // Get all events
  useEffect(() => {
    fetch('/api/get_events')
      .then(res => res.json())
      .then(data => {
        dispatch(fetchEvents(data))
      });
  }, []);

  return (
    <div className="h-full bg-[#F2EDE74C]">
      <Header />
      <ConfirmDialog />
      <div className="max-w-screen-xl mx-auto mt-6 main">
        <p className="mb-2 text-xl font-bold">Events</p>
        <div style={{ display: 'none', inset: 0, position: 'fixed' }} ref={userInputRef}>
          <div style={{ position: 'absolute', left: '48%', top: '48%' }}>
            <Hearts
              height="80"
              width="80"
              radius="9"
              color="#3C1053"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        </div>
        {
          events.length === 0 ? <div className="flex items-center justify-center h-full mt-16 text-4xl">No events</div> :
            events.map((item, index) => (
              <Event key={index} event={item} tiers={tiers} loading={userInputRef} />
            ))
        }
      </div>
    </div >
  );
}

export default Home;
