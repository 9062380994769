import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox'

function Tiers(props) {
    let {
        tiers,
        handleSelectedTiers
    } = props

    const [selectedTiers, setSelectedTiers] = useState([]);

    const onTierChange = (e) => {
        let _selectedTiers = [...selectedTiers];

        if (e.checked)
            _selectedTiers.push(e.value);
        else
            _selectedTiers = _selectedTiers.filter(tier => tier !== e.value);

        setSelectedTiers(_selectedTiers);

        handleSelectedTiers(_selectedTiers);
    }

    return (
        <>
            {
                tiers.map((tier, index) => (
                    <div className='p-3' key={index}>
                        <div className="flex items-center text-white mr-2 justify-between" >
                            <Checkbox value={tier.id} onChange={onTierChange} checked={selectedTiers.some((item) => item === tier.id)}></Checkbox>
                            <label className='ml-3'>{tier.title}</label>
                            ${parseInt(tier.price)}/mo
                        </div>
                    </div>
                ))
            }
        </>
    );
}

export default Tiers;
