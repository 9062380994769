import React from 'react';

function MembershipTag(props) {
    let backgroundColors = ['#faad14', '#722ed1', '#87d068', '#108ee9', '#2db7f5', '#13c2c2', '#f50']
    let {
        no,
        title,
        cnt
    } = props

    let selectedColorIndex = no;

    return (
        <div className={`flex items-center text-white p-2 rounded-lg mr-2`} style={{ backgroundColor: backgroundColors[selectedColorIndex] }}>
            <svg className="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
            </svg>
            {title} - {cnt}
        </div>
    );
}

export default MembershipTag;
