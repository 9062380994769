import { FETCH_EVENTS, DELETE_EVENT } from '../actions/config';

const initialState = {
    events: [],
    refresh: false
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS: {
            return {
                ...state,
                events: action.payload
            }
        }
        case DELETE_EVENT: {
            return {
                events: state.events.filter((event) => event.id !== action.payload),
                refresh: !state.refresh,
            };
        }
        default:
            return state;
    }
}

export default eventReducer;