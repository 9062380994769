import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal'
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import { AiOutlineDelete, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

import { deleteEvent } from '../actions/eventAction'

import MembershipTag from './MembershipTag';
import Tiers from './Tiers';

function Event(props) {
    const dispatch = useDispatch()
    let {
        event,
        tiers,
        loading
    } = props

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0, 0.5)',
        },
        content: {
            background: 'rgba(39, 39, 42, 0.9)',
            border: '1px solid #888',
            top: '45%',
            padding: '30px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            marginBottom: '20px',
            height: 'fit-content'
        },
    };

    const toast = useRef(null);
    const [selectedTiers, setSelectedTiers] = useState([])
    const [eventDetails, setEventDetails] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [upcomingSessoinCnt, setUpcomingSessionCnt] = useState(0)
    const [pastSessionCnt, setPastSessionCnt] = useState(0)
    const [savedTiers, setSavedTiers] = useState([])
    const [totalRegisterd, setTotalRegisterd] = useState(0)
    const eventObj = useSelector(state => state.eventObj)


    useEffect(() => {
        fetch(`/api/event/${event.id}`, {
            'methods': 'GET'
        })
            .then(res => res.json())
            .then(data => {
                setEventDetails(data)
                getSessions(data)
            });
    }, []);

    useEffect(() => {
        fetch(`/api/get_event_tiers/${event.id}`, {
            'methods': 'GET'
        })
            .then(res => res.json())
            .then(data => {
                setSavedTiers(data)

                let total = 0;
                for (let i = 0; i < data.length; i++) {
                    total += data[i].registrant_cnt
                }

                setTotalRegisterd(total)
            });
    }, [eventObj.refresh]);

    const closeModal = () => {
        setIsOpen(false);
        setSelectedTiers([])
    }

    const showSelectTiersModal = () => {
        if (eventDetails.next_date_id === null) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'You can not register members to the past event', life: 3000 });
            return
        }

        if (savedTiers.length === 0)
            setIsOpen(!isOpen)
        else
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'You should delete the event before updaing this one.', life: 3000 });
    }

    // Show membership tags
    const handleConfirmRegisterMembers = () => {
        if (selectedTiers.length === 0) {
            toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You should select one or more memberships.', life: 3000 });
            return
        }

        setIsOpen(false)
        confirmDialog({
            message: 'Are you sure you want to set memberships and register members to your event?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptSaveTiersFunc(),
            reject: () => rejectSaveTiersFunc()
        });
    }

    const acceptSaveTiersFunc = () => {
        let tags = selectedTiers.map((item, index) => (
            tiers.filter(tier => tier.id === item)
        ))

        let tier_tag_colors = []
        tier_tag_colors = tags.map((item, index) => {
            let no = tiers.findIndex(tier => tier.id === item[0].id)
            return no
        })

        const loadingBar = loading.current
        loadingBar.style.display = 'block'

        fetch(`/api/save_event_tiers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                event_id: event.id,
                tiers: selectedTiers,
                tier_tags: tier_tag_colors
            })
        })
            .then(res => res.json())
            .then(data => {

                if (data.success === 'success') {
                    loadingBar.style.display = 'none'
                    setSavedTiers(data.tiers)

                    let total = 0;
                    let tier_rows = data.tiers
                    for (let i = 0; i < tier_rows.length; i++) {
                        total += tier_rows[i].registrant_cnt
                    }

                    setTotalRegisterd(total)
                    toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Memberships are set successfully.', life: 3000 });
                } else if (data.success === 'no-members') {
                    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'No available members.', life: 3000 });
                } else if (data.success === 'failed') {
                    toast.current.show({ severity: 'warn', summary: 'Confirmed', detail: 'Failed register members.', life: 3000 });
                }
            })
            .catch(err => {
                console.log(err)
                loadingBar.style.display = 'none'
            })
    }

    const rejectSaveTiersFunc = () => {
        setSelectedTiers([])
    }

    // Start reset function
    const habdleDeleteEvent = () => {
        confirmDialog({
            message: 'Are you sure delete the event?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => acceptDeleteFunc(),
            reject: () => rejectDeleteFunc()
        })
    }

    const acceptDeleteFunc = () => {
        fetch(`/api/delete_event/${event.id}`
        )
            .then(res => res.json())
            .then(data => {
                if (data.success === true) {
                    dispatch(deleteEvent(event.id))
                }
            });
    }

    const rejectDeleteFunc = () => {

    }

    const getSessions = (eventDetails) => {
        let index = eventDetails.dates.findIndex(item => item.date_id === eventDetails.next_date_id)
        let upcomingCnt = eventDetails.dates.length - index
        let pastCnt = eventDetails.dates.length - upcomingCnt
        setUpcomingSessionCnt(upcomingCnt)
        setPastSessionCnt(pastCnt)
    }

    return (
        <div className="w-full mb-6 cursor-pointer lg:max-w-full lg:flex hover:shadow-xl">
            <Toast ref={toast} />
            <div className="flex flex-col justify-between w-full px-6 py-4 leading-normal bg-white border border-gray-400 rounded">
                <div className="mb-4">
                    <div className='flex justify-between mb-2'>
                        <p className="text-2xl">{event.name}</p>
                        <button className='bg-[#f50] border flex items-center text-white px-2 py-1.5 border-[#f50] rounded-lg hover:text-[#f50] hover:bg-white' onClick={habdleDeleteEvent}>
                            <AiOutlineDelete className="w-4 h-4 mr-1" />
                            Delete event
                        </button>
                    </div>
                    <p className="mb-2 text-sm">
                        {
                            eventDetails != null && (eventDetails.next_date_id === null ? `Finished : ${eventDetails.dates[0].datetime}` : `Scheduled : ${eventDetails.dates.find(item => eventDetails.next_date_id === item.date_id).datetime}`)
                        }
                    </p>
                    <p className="mb-2 text-sm">
                        {
                            eventDetails != null && (eventDetails.next_date_id === null ? `${eventDetails.dates.length} past session` : pastSessionCnt === 0 ? `${upcomingSessoinCnt} upcoming sessions` : `${upcomingSessoinCnt} upcoming sessions | ${pastSessionCnt} past sessions`)
                        }
                    </p>
                    <p className="mb-2 text-sm">
                        {
                            eventDetails != null && (`${totalRegisterd} Registerd`)
                        }
                    </p>
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex text-sm">
                        {
                            savedTiers && (savedTiers.length === 0 ?
                                'No selected tiers' :
                                savedTiers.map((item, index) => {
                                    let title = tiers.find(tier => tier.id === item.tier_id).title
                                    return (
                                        <MembershipTag title={title} key={index} no={item.tag_no} cnt={item.registrant_cnt} />
                                    )
                                }
                                )
                            )
                        }
                    </div>
                    <button className='bg-[#3C1053] border flex items-center text-white px-2 py-1.5 border-[#3C1053] rounded-lg hover:text-[#3C1053] hover:bg-white' onClick={showSelectTiersModal}>
                        <svg className="w-3 h-3 mr-1 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                        </svg>
                        Select tiers
                    </button>
                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    class="modal-response"
                    style={customStyles}
                    contentLabel="Prompt Modal"
                >
                    <div className="my-2 text-sm text-center text-white">
                        <h2 className="mt-4 mb-2 text-3xl">Select Memberships</h2>
                        <div className='pb-2 mb-2 border-b-2'>You can select memberships to be set to your event.</div>
                        <Tiers tiers={tiers} handleSelectedTiers={setSelectedTiers} />
                    </div>
                    <div className='flex float-right mr-5 modal-footer'>
                        <button
                            title="Register members to the event"
                            className="bg-[#16acc9] text-white flex mt-2 mr-3 items-center justify-center p-2 rounded-md shadow-lg"
                            onClick={handleConfirmRegisterMembers}
                        >
                            <AiOutlineCheck className="w-4 h-4 mr-2 text-white" /> Register Members
                        </button>
                        <button
                            className="bg-[#f50] text-white flex mt-2 items-center justify-center p-2 rounded-md shadow-lg"
                            onClick={closeModal}
                        >
                            <AiOutlineClose className="w-4 h-4 mr-2 text-white" /> No
                        </button>
                    </div>
                </Modal>
            </div >
        </div >
    );
}

export default Event;