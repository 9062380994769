import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../assets/images/sj-logo.svg'

function Header() {
    return (
        <header className="bg-[#F2EDE7E5]">
            <nav className="mx-auto flex justify-center" aria-label="Global">
                <div className="">
                    <a href="#" className="p-1">
                        <img className="w-auto" src={Logo} alt="" />
                    </a>
                </div>
            </nav>
            <div className="main-menu flex justify-center">
                <Link to="/" className="text-3xl text-[#3C1053] mb-2 hover:underline decoration-1">Event Registration</Link>
            </div>
        </header>
    );
}

export default Header;
