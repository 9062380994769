import { FETCH_EVENTS, DELETE_EVENT, } from './config';

export const fetchEvents = (payload) => ({
    type: FETCH_EVENTS,
    payload
});

export const deleteEvent = (payload) => ({
    type: DELETE_EVENT,
    payload
});